<template>
  <v-container
    class="export-product-ads-container elevation-5"
    fluid>
    <v-row>
      <v-col
        cols="7"
        sm="7"
        md="3"
        lg="3"
        xl="3"
      >
        <h2>Export Product Ads</h2>
      </v-col>
      <v-col
        cols="5"
        sm="5"
        md="2"
        lg="2"
        xl="2"
        class="d-flex justify-end">
        <v-btn-toggle
          v-model="currencyToggle"
          mandatory
        >
          <v-btn
            v-for="(c, ind) in currencyBtn"
            :key="ind">
            {{ c.toUpperCase() }}
          </v-btn>
        </v-btn-toggle>
      </v-col>
      <v-col
        cols="12"
        sm="12"
        md="7"
        lg="7"
        xl="7"
      >
        <search-by-tags-and-brand
          v-model="query"
          append-icon="mdi-magnify"
          only-brand
          :is-mobile="isMobile"
          @on-search="searchProducts()" />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-data-table
          :loading="loading"
          :headers="headers"
          :items="products"
          :page.sync="query.page"
          :items-per-page="query.perPage"
          hide-default-footer
          class="elevation-1"
          @page-count="pageCount = $event">
          <template v-slot:[`item.photoUrls`]="{ item }">
            <img
              class="product-image"
              :src="item.photoUrls[0]"
              :alt="item.name">
          </template>

          <template v-slot:[`item.price`]="{ item }">
            {{ getPrice(item.price) }} {{ currencyIco[currencyToggle] }}
          </template>

          <template v-slot:[`item.tags`]="{ item }">
            <v-chip
              v-for="(tag, index) in item.tags"
              :key="`tag-chip-${index}`"
              class="mr-1 mt-1"
              color="secondary"
              text-color="white"
              small
              label>
              {{ tag }}
            </v-chip>
          </template>

          <template v-slot:[`item.categories`]="{ item }">
            <v-chip
              v-for="(category, index) in item.categories"
              :key="`category-chip-${index}`"
              class="ma-1"
              color="secondary"
              text-color="white"
              small
              label>
              {{ category }}
            </v-chip>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-pagination
          v-model="query.page"
          :length="pageCount"
          total-visible="7" />
      </v-col>
    </v-row>
    <v-row>
      <v-spacer></v-spacer>
      <v-col cols="auto">
        <v-btn
          :disabled="!products.length"
          color="secondary"
          @click="exportDataToCSV()">
          <v-icon>mdi-file-export</v-icon>
          Export CSV
        </v-btn>
      </v-col>
      <v-spacer></v-spacer>
    </v-row>
  </v-container>
</template>

<script>
import { ExportToCsv } from 'export-to-csv'
import ProductProvider from '@/resources/ProductProvider'
import SearchByTagsAndBrand from '@/components/SearchByTagsAndBrand.vue'
import CurrencyProvider from '@/resources/CurrencyProvider'
import { mapActions, mapGetters } from 'vuex'

const CurrencyService = new CurrencyProvider()
const ProductService = new ProductProvider()

export default {
  components: { SearchByTagsAndBrand },
  data: () => ({
    query: {
      page: 1,
      limit: 10,
      search: '',
      tags: [],
      tagOperation: 'OR',
      brand: null
    },
    currencyToggle: 0,
    currencyBtn: ['thb', 'usd'],
    currencyIco: ['฿', '$'],
    products: [],
    loading: false,
    pageCount: 0,
    currencyMapping: {},
    headers: [
      {
        text: 'Image',
        align: 'start',
        sortable: false,
        value: 'photoUrls',
        width: '80px'
      },
      {
        text: 'ID',
        align: 'center',
        sortable: false,
        value: 'aliasId',
        width: '90px'
      },
      {
        text: 'Name',
        align: 'center',
        sortable: false,
        value: 'name',
        width: '340px'
      },
      {
        text: 'Price',
        align: 'end',
        sortable: false,
        value: 'price',
        width: '90px'
      },
      {
        text: 'Tags',
        align: 'start',
        sortable: false,
        value: 'tags',
        width: '300px'
      },
      {
        text: 'Categories',
        align: 'start',
        sortable: false,
        value: 'categories',
        width: '300px'
      }
    ]
  }),
  computed: {
    ...mapGetters({
      storeSetting: 'Store/storeSetting',
      store: 'Store/store'
    }),
    isMobile () {
      return this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.sm
    }
  },
  mounted () {
    this.getCurrencies()
  },
  methods: {
    ...mapActions({
      setSnackbar: 'Components/setSnackbar'
    }),
    getPrice (price) {
      return this.currencyMapping[price] ? this.currencyMapping[price][this.currencyBtn[this.currencyToggle]] : price
    },
    capitalize (str) {
      return str.replace(/\b\w/g, (c) => c.toUpperCase())
    },
    async getCurrencies () {
      try {
        this.loading = true
        const { data: { results } } = await CurrencyService.getCurrencies({ page: 1, limit: 99999 })
        for (const re of results) {
          const usd = re.foriegnCurrencies.find((fc) => fc.isoCode === 'usd')
          this.currencyMapping[re.thb] = {
            thb: re.thb,
            usd: usd?.value || re.thb
          }
        }
      } catch (error) {
        console.error(`[GET-CURRENCIES-ERROR]: ${error.message}`)
      } finally {
        this.loading = false
      }
    },
    async searchProducts () {
      try {
        this.loading = true

        const { data } = await ProductService.getProducts({
          limit: 9999,
          page: 1,
          search: this.query.search,
          tags: this.query.tags,
          tagOperation: this.query.tagOperation,
          brand: this.query.brand
        })

        this.products = data.results
      } catch (error) {
        console.error('getProducts: ', error)
        this.setSnackbar({
          value: true,
          message: `[GET-PRODUCTS-ERROR]: ${error.message}`,
          type: 'error'
        })
      } finally {
        this.loading = false
      }
    },
    getPriceFormat (price) {
      return `${Number(price).toLocaleString('en-US')} ฿`
    },
    exportDataToCSV () {
      const products = JSON.parse(JSON.stringify(this.products))
      const items = products.map((product) => {
        const splitted = product.name.split(' - ')
        splitted.splice(splitted.length - 1, 1)
        const title = (splitted.length) ? this.capitalize(splitted.join(' - ')) : this.capitalize(product.name)
        const baseUrl = {
          'Gentlewoman': 'https://www.gentlewomanonline.com/product',
          'Gentlewoman Universe': 'https://www.gentlewomanonline.com/product',
          'Gentlelittlewoman': 'https://www.gentlelittlewoman.com/product',
          'Matter Makers': 'https://www.matter-makers.com/product'
        }

        const url = baseUrl[this.query.brand] || this.storeSetting.webUrl

        return {
          id: product.aliasId,
          title,
          description: product.name,
          availability: 'in stock',
          price: this.getPrice(product.price),
          link: `${url}/${product.aliasId}`,
          image_link: product.photoUrls[0] || '',
          additional_image_link: product.photoUrls[1] || '',
          brand: product.brand,
          age_group: 'adult',
          gender: 'female',
          pattern: 'stripes1',
          product_type: 'Apparel & Accessories > Clothing > Shirts',
          custom_label_0: product.tags[0] || '',
          custom_label_1: product.tags[1] || '',
          custom_label_2: product.tags[2] || '',
          custom_label_3: product.tags[3] || '',
          custom_label_4: product.tags[4] || '',
          custom_label_5: product.tags[5] || '',
          condition: 'new'
        }
      })

      const options = {
        filename: 'Products',
        showLabels: true,
        useKeysAsHeaders: true
      }

      const csvExporter = new ExportToCsv(options)
      csvExporter.generateCsv(items)
    }
  }
}
</script>

<style scoped>
.export-product-ads-container {
  background-color: #fff;
  height: fit-content;
  border-radius: 5px;
}
.product-image {
  width: 80px;
  height: auto;
  margin: 10px 0;
}
</style>
